<template>
    <div class="py-4 container-fluid">
      <div class="mt-0 row">
        <div id="send-history" class="card mt-4">
          <!-- Card header -->
          <div class="card-header">
            <h6 class="mb-0">발신번호 조회</h6>
          </div>
          <div class="card-body pt-0">
            <div class="row align-items-center mt-2">
              <div class="col-6 text-start">
                <div style="max-width: 180px;">
                  <material-input
                    id="from_phone_number"
                    v-model="from_phone_number"
                    type="text"
                    size="sm"
                    label="발신번호"
                    label-size="sm"
                    variant="outline"
                  >
                  </material-input>
                </div>
              </div>
              <div class="col-6 text-end">
                <material-button color="primary" size="sm" variant="default" style="width: 120px" @click="changeData">
                  조회
                </material-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 row">
        <div class="mb-4 card">
          <div class="pb-0 card-header">
            <h6>조회 결과</h6>
          </div>
          <EasyDataTable
            table-class-name="customize-table"
            empty-message="조회된 내역이 없습니다."
            rows-per-page-message="페이지당 표시 행 수"
            rows-of-page-separator-message="중"
            :headers="tableColumns"
            :items="fromPhoneNumberList"
            :loading="isLoading"
          >
            <template #loading>
              <img
                src="@/assets/img/Spinner-1s-200px.gif"
                style="width: 100px; height: 100px;"
              />
            </template>
          </EasyDataTable>
        </div>
      </div>
    </div>
  </template>
  <script>
  import MaterialButton from "@/components/MaterialButton.vue";
  import ApiCall from "@/Interface/ApiCall";
  import MaterialInput from "@/components/MaterialInput.vue";
  
  import MaterialTable from "@/components/MaterialTable.vue";
  import MaterialPagination from "@/components/MaterialPagination.vue";
  
  export default {
    name: "DataTables",
    components: {
      MaterialButton,
      // MaterialTable,
      // MaterialPagination,
      MaterialInput,
    },
    setup() {
    },
    data() {
      return {
        // tableColumns: ['발송번호','번호유형', '인증방법', '상태', '요청일자', '승인일자',''],
        tableColumns : [
          { text: "회사/부서명", value: "primary", sortable: true },
          { text: "발송번호", value: "from_phone_number", sortable: true},
          { text: "번호유형", value: "type_korean", sortable: true},
          { text: "인증방법", value: "auth_type_korean", sortable: true},
          { text: "상태", value: "phone_status", width: 300},
          { text: "요청일자", value: "create_timestamp", sortable: true},
          { text: "승인일자", value: "authorize_timestamp", sortable: true},
          { text: "반려Comment", value: "comment_text", sortable: true},
          { text: "", value: "info", width: 10, sortable: true},
        ],
        tableData: [],

        // currentTimestamp: Date.now(),
        fromPhoneNumberList: [], // Add this line
        from_phone_number: '',
        selectedRow: null,
        selectedList: null,
        itemsPerPage: 20,
        currentPage: 1, // Add this line
  
        isLoading: false,
      }
    },
    computed: {
        paginatedFromPhoneNumberList() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.fromPhoneNumberList.slice(startIndex, endIndex);
        },
    },
    methods: {
      onPageChange(pageNumber) {
        this.currentPage = pageNumber;
      },
      onRowClicked({ list, index }) {
        this.selectedList = list;
        this.selectedRow = index;
        console.log(JSON.stringify(this.selectedList));
      },
      getFormattedDate(daysBefore = 0) {
        const currentTimestamp = Date.now();
        const date = new Date(currentTimestamp - daysBefore * 24 * 60 * 60 * 1000);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
      changeDateToString(dateString) {
        const dateObj = new Date(dateString);
        const year = dateObj.getFullYear();
        const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
        const day = ("0" + dateObj.getDate()).slice(-2);
        const hour = ("0" + dateObj.getHours()).slice(-2);
        const minute = ("0" + dateObj.getMinutes()).slice(-2);
        const second = ("0" + dateObj.getSeconds()).slice(-2);
        return `${year}${month}${day}${hour}${minute}${second}`;
      },
      changeData() {
        this.isLoading = true;       
  
        this.currentPage = 1;
  
        const payload = {
          from_phone_number : this.from_phone_number,
        }  
        ApiCall.call('phone/from/list', 'POST', payload).then(res => {
            console.log(res);
          if (!res.data || !res.data.length) {
            // Handle case where no items were found
            console.log('No items found');
            this.isLoading = false;
            return;
          }
          const fromPhoneNumberList = res.data.map(item => {
            let iconClass = '';
            let primary_image = '';
            let primary_image_color = '';
            let secondary = '';
            let secondary_color = '';
            let type_korean = '';
            let auth_type_korean = '';
            let phone_status = '';
            //success(green), info(blue), warning(orange), danger(red), primary(red), secondary(grey), dark(black)
            if (item.from_phone_number_type === 'company') {
                type_korean = '법인번호';
            } else if (item.from_phone_number_type === 'individual') {
                type_korean = '개인번호';
            }
            if (item.authorize_type === 'document') {
                auth_type_korean = '서류인증';
            } else if (item.authorize_type === 'authentication') {
                auth_type_korean = '본인인증';
            }
            if (item.from_phone_number_status === 'active') {
                phone_status = '활성화';
            } else if (item.from_phone_number_status === 'reject') {
                phone_status = '반려';
            } else if (item.from_phone_number_status === 'inactive') {
                phone_status = '비활성화';
            } else if (item.from_phone_number_status === 'deleted') {
                phone_status = '삭제';
            } else if (item.from_phone_number_status === 'request') {
                phone_status = '요청중';
            } else if (item.from_phone_number_status === 'review') {
                phone_status = '검토중';
            }

            return {
              primary: item.tenant_name,
              from_phone_number: item.from_phone_number,
              // values: [
              //   type_korean,
              //   auth_type_korean,
              //   phone_status,
              //   item.create_timestamp,
              //   item.authorize_timestamp,
              // ],
              type_korean: type_korean,
              auth_type_korean: auth_type_korean,
              phone_status: phone_status,
              create_timestamp: item.create_timestamp,
              authorize_timestamp: item.authorize_timestamp,
              secondary: '',
              secondary_color: secondary_color,
              info: '',
              image: primary_image,
              image_color: primary_image_color,
              icon: '',
              comment_text: item.comment_text,
            };
  
            function formatDate(dateString) {
              const year = dateString.substring(0, 4);
              const month = dateString.substring(4, 6);
              const day = dateString.substring(6, 8);
              const hour = dateString.substring(8, 10);
              const minute = dateString.substring(10, 12);
              const second = dateString.substring(12, 14);
  
              return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
            }
          });
  
          // console.log(res.data);
          // console.log(sendHistoryList)
          this.tableData = res.data.data;
          this.fromPhoneNumberList = fromPhoneNumberList;
  
          // Update current page if it exceeds the number of pages available
          const maxPage = Math.ceil(this.fromPhoneNumberList.length / this.itemsPerPage);
          if (this.currentPage > maxPage) {
            this.currentPage = maxPage;
          }
          this.isLoading = false;
        });
      },
    },
  };
  </script>
  <style>
  .spinner-icon {
    font-size: 40px;
  }
  </style>
